export default {
  rules() {
    const rules = {
      required: (value) => {
        return value !== null ? String(value).trim() !== "" : false;
      },
      emailStrict: (value) => {
        if (value.trim() !== "") {
          const re = new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
          return re.test(value) && value === encodeURI(value);
        } else {
          return true;
        }
      },
      password: (value) => {
        if (value.trim() !== "") {
          const re = new RegExp(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-ZñÑ\d]{8,}$/
          );
          return re.test(value);
        } else {
          return true;
        }
      },
    };
    return rules;
  },
};
