<!--
  This component contains the login page view and manage
  form validation and submit with error reponses.
  Includes the password recovery link.
  The messages are in both languages: spanish and portuguese
-->
<template>
  <CContainer fluid class="login-common text-center">
    <CRow class="header">
      <img src="~@/assets/img/logo-nissan-white.png" alt="logo-nissan" />
    </CRow>
    <CRow align-horizontal="center">
      <img src="~@/assets/img/logo-login.png" class="logo" />
    </CRow>
    <CRow align-horizontal="center">
      <CCol sm="6" md="4" lg="3" xxl="2">
        <CForm @submit.prevent="handleLogin" id="loginForm" novalidate="true">
          <CInput
            placeholder="USUARIO / USER"
            type="email"
            v-model="email"
            required
          />
          <p v-if="!isValidEmail && !!emailValidated" class="error-message">
            {{ messages.es.validation.emailError }} /
            {{ messages.pt.validation.emailError }}
          </p>
          <p
            v-if="errorBlocks.emailRequired && email.length === 0"
            class="error-message"
          >
            {{ messages.es.validation.required }} /
            {{ messages.pt.validation.required }}
          </p>

          <CInput
            placeholder="CONTRASEÑA / PASSWORD"
            type="password"
            v-model="password"
            required
          />
          <p
            v-if="errorBlocks.passwordRequired && password.length === 0"
            class="error-message"
          >
            {{ messages.es.validation.required }} /
            {{ messages.pt.validation.required }}
          </p>

          <ErrorMessage
            v-if="errorManager !== null"
            :message="errorManager.message"
            :styleClass="errorManager.styleClass"
          />

          <CButton type="submit" color="primary" :block="true">
            ENTRAR
          </CButton>

          <p class="pass">
            <router-link to="/recuperar-contrasena"
              ><small>¿Has olvidado tu contraseña?</small></router-link
            >
          </p>

          <p class="pass">
            <router-link to="/recuperar-contrasenha"
              ><small>Esqueceu-se da sua password?</small></router-link
            >
          </p>
        </CForm>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
// import UserService from "@/services/UserService";
const ErrorMessage = () =>
  import(
    /* webpackChunkName: "error-message" */ "@/components/atoms/ErrorMessage"
  );
import LoginAndPassword from "@/services/LoginAndPassword";
import validation from "@/utils/validation";
import languages from "@/languages/languages.js";
import langStr from "@/utils/langStr";
import axios from "axios";

export default {
  name: "Login",
  components: {
    ErrorMessage,
  },
  data() {
    return {
      lang: "",
      languages: languages,
      email: "",
      emailValidated: false,
      password: "",
      user: "",
      errorBlocks: {
        emailRequired: false,
        passwordRequired: false,
      },
      errorManager: null,
    };
  },
  computed: {
    messages() {
      return languages.texts();
    },
    isValidEmail() {
      return validation.rules().emailStrict(this.email);
    },
    isEmpty(value) {
      return validation.rules().required(value);
    },
  },
  methods: {
    /**
     * Login Form fields validation
     */
    formValidation(user) {
      if (!validation.rules().required(user.email)) {
        this.errorBlocks.emailRequired = true;
      }
      if (!validation.rules().required(user.password)) {
        this.errorBlocks.passwordRequired = true;
      }
      if (
        !this.isValidEmail ||
        !validation.rules().required(user.email) ||
        !validation.rules().required(user.password)
      ) {
        return false;
      } else {
        return true;
      }
    },
    /**
     * Manage sending Login Form Data
     */
    handleLogin() {
      this.emailValidated = true;

      const user = {
        email: this.email,
        password: this.password,
      };

      if (this.formValidation(user)) {
        const queryURL = "login";
        if (this.isValidEmail === true) {
          LoginAndPassword()
            .post(queryURL, user)
            .then((response) => {
              console.log(response);
              if (response.status === 200) {
                if (response.data.code === 200) {
                  const user = response.data.data.userInfo;
                  const token = response.data.data.token;
                  // Save user & token in Store
                  this.$store.dispatch("saveCurrentUser", user);
                  this.$store.dispatch("saveCurrentToken", token);
                  // Send user data to Google Tag Manager
                  this.gtmTagUserData(user);
                  // Send user profile to Google Tag Manager
                  // this.gtmTagUserProfile(user.profileId);
                  //Evaluate "newuser" property
                  if (String(user.newUser) === "1") {
                    const lang = langStr.get(response.data.languageId);
                    this.$router.push({
                      name: "PasswordCreation",
                      params: { lang: lang, newUser: true },
                    });
                  } else {
                    // Then redirect to dashboard
                    this.$router.push("/dashboard");
                  }
                  // this.$router.push(url);
                } else {
                  // Not Allowed Error (Maintenance)
                  if (response.data.code === 816) {
                    this.$router.push({
                      name: "NotAllowed",
                    });
                  }

                  // Manage API errors
                  const messages = this.languages.texts();
                  this.errorManager = {
                    message: `${messages.es.errors[response.data.code]} | ${
                      messages.pt.errors[response.data.code]
                    }`,
                    styleClass: "error-message",
                  };
                }
              } else {
                // Manage API errors
                const messages = this.languages.texts();
                this.errorManager = {
                  message: `${messages.es.errors[response.status]} | ${
                    messages.pt.errors[response.status]
                  }`,
                  styleClass: "error-message",
                };
              }
            });
        }
      }
    },
    getUserProfile(profileId) {
      let userTypeStr;
      switch (profileId) {
        case -20:
          userTypeStr = "super-admin";
          break;
        case -10:
          userTypeStr = "admin";
          break;
        case 0:
          userTypeStr = "nibsa";
          break;
        case 10:
          userTypeStr = "aspm";
          break;
        case 20:
          userTypeStr = "dealer-admin";
          break;
        case 21:
          userTypeStr = "dealer";
          break;
        case 30:
          userTypeStr = "nfit";
          break;
      }
      return userTypeStr;
    },
    gtmTagUserData(user) {
      const email = user.email;
      const concessionCode =
        user.concessionCode && user.concessionCode !== undefined
          ? String(user.concessionCode)
          : "";
      const userType = this.getUserProfile(user.profileId);
      if (this.$gtm.enabled()) {
        window.dataLayer.push({
          event: "login",
          userType: userType,
          email: email,
          codigoConcesion: concessionCode,
        });
      }
    },
  },
};
</script>
